import { MicrositeCustomTheme, MicrositeTheme } from 'types';
import { baseTheme } from '../baseTheme';

// ==================================
// BEGIN CUSTOM THEMING FOR MICROSITE
// ==================================

const customTheme: MicrositeCustomTheme = {
  name: 'DefaultTheme',
  code: 'default',
  colors: {
    headerBackground: '#CCCCCC',
    headerText: '#000000',
    headerBorder: '#000000',
    background: '#FFFFFF',
    text: '#000000',
    footerBackground: '#CCCCCC',
    footerText: '#000000',
    footerBorder: '#000000',
    progressBar: '#DDDDDD',
  },
  logoSize: {
    width: '168px',
    height: '40px',
  },
  baseColors: {
    link: '#666666',
    cta: '#666666',
    hover: '#333333',
  },
};

// ==================================
// END CUSTOM THEMING FOR MICROSITE
// ==================================

const mergedTheme = Object.assign(baseTheme, {
  colors: {
    ...baseTheme.colors,
    ...customTheme.baseColors,
  },
});

export const DefaultTheme: MicrositeTheme = {
  ...mergedTheme,
  customTheme,
};
